import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Editor from 'vue-editor-js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faSave, faFont, faImage, faEnvelope, faVideo, faLink, faGlobe, faFile, faEdit, faSignOutAlt, faCamera, faNewspaper, faStar, faAddressBook, faCity, faCalendarWeek, faFilePdf, faFileAlt, faHandshake, faKeyboard, faThLarge, faImages , faUniversity, faLaptopHouse} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSimpleAlert from "vue-simple-alert"
import axios from "axios"

library.add(faTrash, faEnvelope, faSave, faFont, faImage, faVideo, faLink, faGlobe, faFile, faEdit, faSignOutAlt, faCamera, faNewspaper, faStar, faAddressBook, faCity, faCalendarWeek, faFilePdf, faFileAlt, faHandshake, faKeyboard, faThLarge, faImages, faUniversity, faLaptopHouse);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Editor);
Vue.config.productionTip = false;
Vue.use(VueSimpleAlert);
Vue.use(require('vue-moment'));

const token = localStorage.getItem('user-token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.prototype.$apiUrl = 'https://api.crars.org.br/';
// Vue.prototype.$apiUrl = 'http://localhost:3000/';
Vue.prototype.$mediaBaseUrl = 'https://api.crars.org.br/media/';
// Vue.prototype.$mediaBaseUrl = 'http://localhost:3000/media/';

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app');
