import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('user-token')) {
    next();
    return;
  }
  next('/')
};

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('user-token')) {
    next();
    return;
  }
  next('/login')
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => {
      localStorage.removeItem('user-token');
      router.push('/login');
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/paginas',
    name: 'pages',
    component: () => {
      return import('../views/pages/Pages.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/paginas/nova',
    name: 'newPage',
    component: () => {
      return import('../views/pages/NewPage.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/paginas/editar/:id',
    name: 'editPage',
    component: () => {
      return import('../views/pages/EditPage.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/banner',
    name: 'banner',
    component: () => {
      return import('../views/banner/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/banner/novo',
    name: 'newbanner',
    component: () => {
      return import('../views/banner/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/banner/editar/:id',
    name: 'editbanner',
    component: () => {
      return import('../views/banner/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/itens-destaque',
    name: 'featuredItems',
    component: () => {
      return import('../views/featured-item/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/itens-destaque/novo',
    name: 'newFeaturedItem',
    component: () => {
      return import('../views/featured-item/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/itens-destaque/editar/:id',
    name: 'editFeaturedItem',
    component: () => {
      return import('../views/featured-item/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias',
    name: 'posts',
    component: () => {
      return import('../views/post/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias/novo',
    name: 'newPost',
    component: () => {
      return import('../views/post/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias/editar/:id',
    name: 'editPost',
    component: () => {
      return import('../views/post/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias-camaras',
    name: 'councilposts',
    component: () => {
      return import('../views/council-post/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias-camaras/novo',
    name: 'newcouncilPost',
    component: () => {
      return import('../views/council-post/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias-camaras/editar/:id',
    name: 'editcouncilPost',
    component: () => {
      return import('../views/council-post/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/clube-de-servicos',
    name: 'membershipclub',
    component: () => {
      return import('../views/membership-club/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/clube-de-servicos/novo',
    name: 'newmembershipclub',
    component: () => {
      return import('../views/membership-club/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/clube-de-servicos/editar/:id',
    name: 'editmembershipclub',
    component: () => {
      return import('../views/membership-club/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/revista-master',
    name: 'mastermagazine',
    component: () => {
      return import('../views/master-magazine/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/revista-master/novo',
    name: 'newmastermagazine',
    component: () => {
      return import('../views/master-magazine/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/revista-master/editar/:id',
    name: 'editmastermagazine',
    component: () => {
      return import('../views/master-magazine/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/parceiros',
    name: 'partners',
    component: () => {
      return import('../views/partners/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/parceiros/novo',
    name: 'newpartner',
    component: () => {
      return import('../views/partners/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/parceiros/editar/:id',
    name: 'editpartner',
    component: () => {
      return import('../views/partners/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/cra-na-imprensa',
    name: 'crapress',
    component: () => {
      return import('../views/cra-press/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/cra-na-imprensa/novo',
    name: 'newcrapress',
    component: () => {
      return import('../views/cra-press/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/cra-na-imprensa/editar/:id',
    name: 'editcrapress',
    component: () => {
      return import('../views/cra-press/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/eventos',
    name: 'event',
    component: () => {
      return import('../views/events/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/eventos/novo',
    name: 'newevent',
    component: () => {
      return import('../views/events/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/eventos/editar/:id',
    name: 'editevent',
    component: () => {
      return import('../views/events/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/editais',
    name: 'edital',
    component: () => {
      return import('../views/edital/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/editais/novo',
    name: 'newedital',
    component: () => {
      return import('../views/edital/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/editais/editar/:id',
    name: 'editedital',
    component: () => {
      return import('../views/edital/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/contato',
    name: 'contact',
    component: () => {
      return import('../views/contact/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/pactoglobal',
    name: 'pactoglobal',
    component: () => {
      return import('../views/pactoglobal/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/portalcolaborador',
    name: 'portalcolaborador',
    component: () => {
      return import('../views/portalcolaborador/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },

  {
    path: '/galerias-colaborador',
    name: 'colabgalleries',
    component: () => {
      return import('../views/galleries-colaborador/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/galerias-colaborador/novo',
    name: 'colabnewgallery',
    component: () => {
      return import('../views/galleries-colaborador/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/galerias-colaborador/editar/:id',
    name: 'colabeditgallery',
    component: () => {
      return import('../views/galleries-colaborador/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },


  {
    path: '/noticias-portal/:council',
    name: 'portalposts2',
    component: () => {
      return import('../views/portal-post/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias-portal/:council/novo',
    name: 'newportalPost2',
    component: () => {
      return import('../views/portal-post/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias-portal/:council/editar/:id',
    name: 'editportalPost2',
    component: () => {
      return import('../views/portal-post/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },

  {
    path: '/cra-no-interior',
    name: 'craint',
    component: () => {
      return import('../views/cra-int/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/camaras',
    name: 'councils',
    component: () => {
      return import('../views/councils/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/camaras/novo',
    name: 'newCouncil',
    component: () => {
      return import('../views/councils/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/camaras/editar/:id',
    name: 'editCouncil',
    component: () => {
      return import('../views/councils/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },

  {
    path: '/noticias-camara/:council',
    name: 'councilposts2',
    component: () => {
      return import('../views/council-post/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias-camara/:council/novo',
    name: 'newcouncilPost2',
    component: () => {
      return import('../views/council-post/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/noticias-camara/:council/editar/:id',
    name: 'editcouncilPost2',
    component: () => {
      return import('../views/council-post/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },

  {
    path: '/artigos',
    name: 'articles',
    component: () => {
      return import('../views/articles/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/artigos/novo',
    name: 'newarticle',
    component: () => {
      return import('../views/articles/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/artigos/editar/:id',
    name: 'editarticle',
    component: () => {
      return import('../views/articles/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },

  {
    path: '/newsletter',
    name: 'newsletters',
    component: () => {
      return import('../views/newsletters/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/newsletter/novo',
    name: 'newnewsletter',
    component: () => {
      return import('../views/newsletters/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/newsletter/editar/:id',
    name: 'editnewsletter',
    component: () => {
      return import('../views/newsletters/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },

  {
    path: '/galerias',
    name: 'galleries',
    component: () => {
      return import('../views/galleries/List.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/galerias/novo',
    name: 'newgallery',
    component: () => {
      return import('../views/galleries/New.vue')
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/galerias/editar/:id',
    name: 'editgallery',
    component: () => {
      return import('../views/galleries/Edit.vue')
    },
    beforeEnter: ifAuthenticated
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
