<template>
  <div id="login">
    <div class="login-wrapper">
      <img alt="E-Marketing logo" src="../assets/icon.svg">
      <h1>Acessar painel</h1>
      <form action="#" method="post" @submit.prevent="login">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" required>
        <label for="password">Senha</label>
        <input type="password" id="password" v-model="password" required>
        <button class="btn" :disabled="loading">{{ loading ? 'Entrando...' : 'Entrar' }}</button>
      </form>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: 'login',
  data() {
    return {
      loading: false,
      email: '',
      password: ''
    }
  },
  methods: {
    login() {
      this.loading = true;
      let self = this;

      axios.post(this.$apiUrl + 'auth/login', {
        email: self.email,
        password: self.password
      })
      .then(response => {
        const token = response.data.token;
        localStorage.setItem('user-token', token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        self.loading = false;
        self.$router.push('/');
      })
      .catch(error => {
        localStorage.removeItem('user-token');
        self.$alert(error.response.data.message, 'Falha na autenticação', 'error');
        self.loading = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/global";
@import "../assets/scss/buttons";
@import "../assets/scss/blocks";

#login {
  position: absolute;
  height: 100vh;
  background-color: $primary;
  width: 100%;
  padding: 50px 20px;

  .login-wrapper {
    position: absolute;
    width: 40%;
    left: 30%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    background-color: $dark;
    padding: 50px;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

    @media screen and (max-width: 900px) {
      width: 90%;
      left: 5%;
    }

    @media screen and (max-width: 600px) {
      padding: 20px;
    }

    img {
      width: 30%;
    }

    h1 {
      text-transform: uppercase;
      margin: 20px 0;
    }

    input {
      display: block;
      width: 100%;
      border: none;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 5px 10px;
      font-size: 1rem;
    }

    .btn:disabled {
      opacity: 0.5;
    }
  }
}
</style>
